import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const GalleryPage = () => {
  const query = graphql`
    query {
      allContentfulServiceCategory(
        sort: { fields: createdAt }
        filter: { gallerySlug: { ne: null } }
      ) {
        edges {
          node {
            image {
              fluid(maxWidth: 255, maxHeight: 255) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            galleryImage {
              fluid(maxWidth: 255, maxHeight: 255) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            id
            name
            slug
            gallerySlug
            displayInHomePage
          }
        }
      }
      contentfulNavigationPages(idDontChange: { eq: "gallery" }) {
        name
        seo {
          title
          description
          keywords
        }
        slug
        hiddenContent {
          childMarkdownRemark {
            html
          }
        }
        bannerImage {
          fluid(maxWidth: 1600) {
            src
          }
        }
        contentImage {
          fluid {
            src
          }
        }
      }
    }
  `
  const data = useStaticQuery(query)
  const pageData = data.contentfulNavigationPages

  return (
    <Layout>
      <SEO
        title={pageData.seo.title}
        description={pageData.seo.description}
        keywords={pageData.seo.keywords}
      />
      <div>
        <section className="inner-intro bg-img-single-service light-color overlay-before parallax-background">
          <div className="container">
            <div className="row title">
              <div className="title_row">
                <h1 data-title="Gallery">
                  <span>Gallery</span>
                </h1>
                <div className="page-breadcrumb">
                  <Link to="/">Home</Link>/ <span>Gallery</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="service" className="padding ptb-xs-40">
          <div className="container">
            <div className="row text-center mb-40">
              <div className="col-lg-6 offset-lg-3 sect-title">
                <h2>
                  <span>Our</span> Gallery
                </h2>
                <span className="title_bdr">
                  <i className="ion-more" />
                </span>
              </div>
            </div>
            <div id="services-widgets" className="row">
              {data.allContentfulServiceCategory.edges.map(service => (
                <div
                  key={service.node.id}
                  className="services-block col-lg-3 col-md-6 col-xs-12 mb-30"
                >
                  <div className="inner-box hvr-float">
                    <Link to={service.node.gallerySlug}>
                      <div className="image">
                        {service.node.galleryImage && (
                          <Img
                            fluid={service.node.galleryImage.fluid}
                            alt={service.node.name}
                          />
                        )}
                      </div>
                      <div className="lower-box">
                        <h3>{service.node.name}</h3>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default GalleryPage
